import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

const Avatar = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visitor, setVisitor] = useState(true);

  const [indexLanguage, setIndexLanguage] = useState('');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const getVisitor = localStorage.getItem('walletAddress');
    if (getVisitor === '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a') {
      setVisitor(true);
    } else {
      setVisitor(false);
    }
  }, []);

  useEffect(() => {
    const getWalletAddress = localStorage.getItem('walletAddress');
    const cachedUser = JSON.parse(localStorage.getItem('cachedUser') || '{}');

    const fetchUserData = async () => {
      try {
        if (cachedUser && cachedUser.wallet === getWalletAddress) {
          // Use cached data if the wallet address is the same
          setUser(cachedUser.userData);
        } else {
          const response = await fetch(
            `${linkUrl}/wp-json/custom/v1/get_user_by_metadata/?value=${getWalletAddress}`
      );
          const userData = await response.json();

          if (userData && userData.length > 0) {
            setUser(userData[0]);
            // Cache the user data
            localStorage.setItem('cachedUser', JSON.stringify({ wallet: getWalletAddress, userData: userData[0] }));
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const getInitials = (name: any) => {
    if (!name) return '';

    const names = name.split(' ');
    return names
      .map((word: any) => word.charAt(0).toUpperCase())
      .join('')
      .slice(0, 2);
  };

  return (
    <div>
     
      {(user && visitor === false) &&(
        <span className="avatar-menu-container">
          <span className="name-menu">{user}&nbsp;</span>
          <span className="avatar-menu">{getInitials(user)}</span>
        </span>
      )}
      {(user && visitor === true) &&(
        <span className="avatar-menu-container">
          <span className=""><i className="fa-solid fa-bars"></i></span>
        </span>
      )}
    </div>
  );
};

export default Avatar;
